@import "./assets/font/NotoSansHans-Medium.css";
@import "./assets/font/OpenSans-Semibold.css";
@import "~normalize.css";

html {
  font-size: 2.5vw;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
  min-height: 100%;
  color: #776e65;
  background: #faf8ef;
}

a {
  font-weight: bold;
  color: #776e65;
}

#root {
  min-height: 100%;
}

@media screen and (min-width: 480px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 640px) and (min-height: 800px) {
  html {
    font-size: 14px;
  }
}

